import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { PostWfSelectedCustomFieldRequest, SelectUniversalLanguage } from '@offconon/core-api';
import { CustomFieldsCommonVirtualScrollDropdownComponent } from '@offconon/shared/ui/custom-fields-common-virtual-scroll-dropdown';

import { CustomFieldSelectEditorRenderModule } from '../../custom-field-select-editor-render.module';
import { SelectedCustomFieldCheckboxComponent } from '../../item/selected-custom-field-checkbox.component';
import { SelectedCustomFieldDropdownComponent } from '../../item/selected-custom-field-dropdown.component';
import { SelectedCustomFieldInputNumberComponent } from '../../item/selected-custom-field-input-number.component';
import { SelectedCustomFieldInputTextComponent } from '../../item/selected-custom-field-input-text.component';
import { SelectedCustomFieldMultiselectComponent } from '../../item/selected-custom-field-multiselect.component';
import { SelectedCustomFieldRadioButtonComponent } from '../../item/selected-custom-field-radio-button.component';
import { SelectedCustomFieldRangeComponent } from '../../item/selected-custom-field-range.component';
import { selectCustomField } from '../../store/custom-field-template.actions';

@Component({
  selector: 'offconon-selected-custom-field-editor-form',
  templateUrl: './selected-custom-field-editor-form.component.html',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CustomFieldsCommonVirtualScrollDropdownComponent,
    SelectedCustomFieldCheckboxComponent,
    SelectedCustomFieldRadioButtonComponent,
    SelectedCustomFieldDropdownComponent,
    SelectedCustomFieldMultiselectComponent,
    SelectedCustomFieldInputNumberComponent,
    SelectedCustomFieldInputTextComponent,
    SelectedCustomFieldRangeComponent,
    ButtonModule,
    TranslateModule,
    CommonModule,
    CustomFieldSelectEditorRenderModule,
  ],
})
export class SelectedCustomFieldEditorFormComponent implements OnInit {
  @Input() parameters: any;
  @Input() wfSelectedCustomField: any | null;
  @Input() customFieldValues?: SelectUniversalLanguage[];
  @Output() closeForm = new EventEmitter<boolean>();
  form: FormGroup;

  prefix: string | undefined | null = undefined;
  suffix: string | undefined | null = undefined;
  allIncomingData: any;
  settedData: any;
  disabled: boolean = true;

  private store$ = inject(Store);

  private fb = inject(FormBuilder);

  ngOnInit() {
    if (this.wfSelectedCustomField && this.wfSelectedCustomField.custom_field) {
      this.allIncomingData = this.wfSelectedCustomField;
      this.wfSelectedCustomField = this.wfSelectedCustomField.custom_field;
    }
    let selectedIds = this.allIncomingData?.selected_custom_field?.selected_value?.id
      ? this.allIncomingData?.selected_custom_field?.selected_value.id
      : undefined;
    if (!selectedIds) {
      selectedIds = this.allIncomingData?.selected_custom_field?.selected_value?.id
        ? [this.allIncomingData.selected_custom_field.selected_value.id]
        : this.allIncomingData?.selected_custom_field?.selected_values?.map(
            (value: any) => value.id,
          ) || undefined;
    }

    this.form = this.fb.group({
      id: [this.allIncomingData ? this.allIncomingData.selected_custom_field?.id : undefined],
      custom_field_id: [this.wfSelectedCustomField?.id],
      service_type: [this.parameters?.service_type, Validators.required],
      service_id: [this.parameters?.service_id, Validators.required],
      business_id: [this.parameters?.business_id],
      custom_number: [this.allIncomingData ? this.allIncomingData.custom_number : undefined],
      custom_text: [this.allIncomingData ? this.allIncomingData.custom_text : undefined],
      selected_universal_language_field_ids: [selectedIds],
      range_id: [
        this.allIncomingData?.selected_custom_field?.selected_range_value?.id
          ? this.allIncomingData?.selected_custom_field?.selected_range_value.id
          : undefined,
      ],
    });
    this.form.patchValue({});
    this.form.valueChanges.subscribe(() => {
      this.checkValidation();
    });
  }
  checkValidation() {
    if (
      this.form.value.selected_universal_language_field_ids ||
      this.form.value.range_id ||
      this.form.value.custom_text ||
      this.form.value.custom_number
    ) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  get customFieldFormControl() {
    return this.form.get('custom_field_id') as FormControl;
  }

  setCustomField(customField: any) {
    try {
      this.form.patchValue({
        selected_universal_language_field_ids: null,
        range_id: null,
      });
      this.settedData = [];
      this.settedData = customField?.additionalData;
      /*
      if (customField) {
        this.form.patchValue({
          custom_field_id: customField.id,
        });
      }*/
    } catch (error) {}
  }

  submit() {
    const selectedData: PostWfSelectedCustomFieldRequest = {
      id: this.form.value.id,
      custom_field_id: this.form.value.custom_field_id,
      service_type: this.form.value?.service_type,
      service_id: Number(this.form.value.service_id),
      business_id: this.form.value.business_id,
      custom_number: this.form.value.custom_number ?? undefined,
      custom_text: this.form.value.custom_text ?? undefined,
      range_id: this.form.value.range_id ?? undefined,
      selected_universal_language_field_ids: this.getUniversalLanguageFieldIds(),
    };
    this.store$.dispatch(
      selectCustomField({
        selectedCustomField: selectedData,
        catalogId: this.parameters?.service_id,
      }),
    );
    this.closeForm.emit(true);
  }

  getUniversalLanguageFieldIds() {
    if (!this.form.value.selected_universal_language_field_ids) {
      return undefined;
    }
    if (!Array.isArray(this.form.value.selected_universal_language_field_ids)) {
      return [this.form.value.selected_universal_language_field_ids];
    }

    return this.form.value.selected_universal_language_field_ids;
  }
}
