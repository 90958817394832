import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { TruncatePipe } from 'libs/shared/utils/pipes/src/lib/truncate.pipe';
import { ConfirmationService, ConfirmEventType, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { PaginatorState, PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { Observable } from 'rxjs';

import { CommonCustomFieldCategory } from '@offconon/core-api';
import { SkeletonRenderSampleWideCardComponent } from '@offconon/shared/ui/skeleton-render';

import { CustomFieldSelectEditorRenderModule } from '../../custom-field-select-editor-render.module';
import { SelectedCustomFieldEditorFormComponent } from '../../form/selected-custom-field-editor-form/selected-custom-field-editor-form.component';
import { SelectedCustomFieldEditorElementComponent } from '../../item/selected-custom-field-editor-element/selected-custom-field-editor-element.component';
import {
  deleteSelectedCustomField,
  fetchCustomFieldTemplate,
  fetchSelectedCustomFieldTemplate,
} from '../../store/custom-field-template.actions';
import {
  selectCustomFieldTemplateLoaded,
  selectPaginatedWfSelectedCustomFieldList,
  selectSelectedCustomFieldList,
} from '../../store/custom-field-template.selectors';
@Component({
  selector: 'offconon-custom-field-select-editor-render',
  templateUrl: './custom-field-select-editor-render.component.html',
  providers: [ConfirmationService],
  imports: [
    CardModule,
    ButtonModule,
    DividerModule,
    SkeletonRenderSampleWideCardComponent,
    SelectedCustomFieldEditorElementComponent,
    PaginatorModule,
    SelectedCustomFieldEditorFormComponent,
    ConfirmDialogModule,
    AsyncPipe,
    TranslateModule,
    PanelModule,
    TruncatePipe,
    CommonModule,
    CustomFieldSelectEditorRenderModule,
  ],
})
export class CustomFieldSelectEditorRenderComponent implements OnInit {
  private translateService = inject(TranslateService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);

  @Input() parameters: any;
  @Input() collapsedStatus = false;
  @Input() truncate_number = 35;
  @Input() scrollTo = 0;

  private store$ = inject(Store);
  selectedCustomField: CommonCustomFieldCategory | null;

  selectedCustomFieldList$: Observable<any>;
  paginatedWfSelectedCustomFieldList$ = this.store$.select(
    selectPaginatedWfSelectedCustomFieldList,
  );

  loading$ = this.store$.select(selectCustomFieldTemplateLoaded);
  show_all = true;

  desc: any;
  is_editable = true;

  hide_new_custom_filed = false;

  ngOnInit() {
    this.desc = this.translateService.instant(
      'Here you can add custom fields to the template. We will use these fields in our comparison module where customers compare the details of the offers. Customers can also filter these fields in our search module.',
    );

    if (
      this.parameters?.custom_filed_service_type === 'plant' ||
      this.parameters?.custom_filed_service_type === 'physical_product' ||
      this.parameters?.custom_filed_service_type === 'product'
    ) {
      this.desc = this.translateService.instant(
        'Here you can add specifications for the item. These fields will be used in our comparison module, where members will compare the item details. Members can also filter these fields in our search module.',
      );
    }
    this.store$.dispatch(
      fetchSelectedCustomFieldTemplate({
        page: 1,
        pageSize: 10,
        searchJson: { service_id: this.parameters?.service_id },
      }),
    );

    this.store$.dispatch(
      fetchCustomFieldTemplate({
        searchJson: {
          service_type: this.parameters?.service_type,
          service_id: this.parameters?.service_id,
          category_types: [this.parameters.custom_filed_service_type],
        },
      }),
    );
    this.selectedCustomFieldList$ = this.store$.select(selectSelectedCustomFieldList);
  }

  paginationChanged(event: PaginatorState) {
    this.store$.dispatch(
      fetchSelectedCustomFieldTemplate({
        searchJson: {
          service_type: this.parameters?.service_type,
          service_id: this.parameters?.service_id,
        },
        page: (event.page || 0) + 1,
        pageSize: event?.rows || 10,
      }),
    );
  }

  hideAll() {
    this.show_all = false;
    this.selectedCustomField = null;
  }

  showAll() {
    this.show_all = true;
    this.selectedCustomField = null;
  }

  closeForm() {
    this.selectedCustomField = null;

    this.show_all = true;
  }

  editClick(data: any) {
    this.selectedCustomField = data;
    this.show_all = false;
  }

  hideSave(data: any) {
    this.hide_new_custom_filed = data;
  }

  confirmDelete(data: any, selected?: boolean) {
    if (!data.selected_custom_field.id || selected) return;
    if (!selected) {
      this.confirmationService.confirm({
        message:
          this.translateService.instant('Are you sure you want to delete this?') +
          ' ' +
          this.translateService.instant('This decision can not be reversed!'),
        header: this.translateService.instant('Delete'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store$.dispatch(
            deleteSelectedCustomField({
              id: data.selected_custom_field.id as number,
            }),
          );
          this.messageService.add({
            severity: 'info',
            summary: this.translateService.instant('Delete'),
            detail: this.translateService.instant('The item has been deleted!'),
          });
        },
        reject: (type: any) => {
          switch (type) {
            case ConfirmEventType.REJECT:
            case ConfirmEventType.CANCEL:
              this.messageService.add({
                severity: 'warn',
                summary: this.translateService.instant('Cancel'),
                detail: this.translateService.instant('This item has remained active!'),
              });
              break;
          }
        },
        key: 'customSelectedtemDialog',
      });
    }
  }
}
