import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from 'primeng/select';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-range',
  imports: [SelectModule, ReactiveFormsModule, FormsModule],
  template: `
    <p-select
      optionLabel="name"
      [required]="true"
      [optionValue]="'id'"
      [styleClass]="'w-full'"
      [formControl]="control"
      [options]="this.selectedCustomField?.range_values || []"
      [showClear]="false">
      <ng-template #item let-optionValue>
        {{ optionValue.from_value }} -
        {{ optionValue.to_value }}
        {{ suffix }}
      </ng-template>

      <ng-template #selectedItem let-selectedItem>
        @if (selectedItem) {
          {{ selectedItem.from_value }} -
          {{ selectedItem.to_value }}
          {{ suffix }}
        }
      </ng-template>
    </p-select>
  `,
  styles: ``,
})
export class SelectedCustomFieldRangeComponent extends AbstractSelectedCustomFieldComponent {}
