@if (selectedCustomField?.custom_field?.keyword?.translations?.[0]?.translation) {
  <div class="border-b border-slate-100 mb-4 pb-2">
    <div class="flex flex-col">
      <div class="font-bold">
        {{ selectedCustomField?.custom_field?.keyword?.translations?.[0]?.translation }}
      </div>

      <div class="light leading-6">
        @if (prefix) {
          <span class="text-red-500"> {{ prefix }} </span>
        }
        <span class="font-italic">
          {{ selectedValue }}
        </span>

        @if (suffix) {
          <span class="text-blue-500"> {{ suffix }} </span>
        }
      </div>
    </div>

    @if (is_editable) {
      <div class="flex flex-row-reverse flex-wrap">
        @if (!selectedCustomField?.selected_custom_field?.is_required) {
          <div class="flex items-center justify-center" (click)="deleteClick()">
            <span class="text-xs text-red-700 m-2 cursor-pointer">
              <i class="pi pi-trash mr-1 text-xs"></i>
              {{ 'Delete' | translate }}
            </span>
          </div>
        }
        <div class="flex items-center justify-center" (click)="editClick()">
          <span class="text-xs text-teal-700 m-2 cursor-pointer">
            <i class="pi pi-user-edit mr-1 text-xs"></i>
            {{ 'Edit' | translate }}
          </span>
        </div>
      </div>
    }
  </div>
}
