<form class="grip w-full max-w-full pb-5" [formGroup]="form" (submit)="submit()">
  <div class="font-bold">
    {{ wfSelectedCustomField?.keyword?.translations?.[0]?.translation }}
  </div>
  <div class="flex flex-col">
    @if (!wfSelectedCustomField) {
      <div class="flex flex-col gap-2 mb-4">
        <label class="font-bold"> {{ 'Custom field' | translate }}</label>

        <offconon-custom-fields-common-virtual-scroll-dropdown
          [parameters]="parameters"
          [customFieldFormControl]="customFieldFormControl"
          (selectedCustomFieldEvent)="setCustomField($event)" />
      </div>
    }
    @if ((settedData || wfSelectedCustomField) && form) {
      @switch (
        wfSelectedCustomField ? wfSelectedCustomField.custom_type : settedData?.custom_type
      ) {
        @case ('checkbox') {
          <div class="grip w-full max-w-full pb-1 pt-1">
            <offconon-selected-custom-field-checkbox
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }
        @case ('radio') {
          <div class="w-full">
            <offconon-selected-custom-field-radio-button
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }
        @case ('dropdown') {
          <div class="field w-full mt-4 mb-4">
            <offconon-selected-custom-field-dropdown
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }

        @case ('multi_select') {
          <div class="field w-full">
            <offconon-selected-custom-field-multiselect
              [control]="$any(form.get('selected_universal_language_field_ids'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }
        @case ('input_number') {
          <div class="field w-full">
            <offconon-selected-custom-field-input-number
              [control]="$any(form.get('custom_number'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }
        @case ('text') {
          <div class="field w-full">
            <offconon-selected-custom-field-input-text
              [control]="$any(form.get('custom_text'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }

        @case ('range') {
          <div class="field w-full">
            <offconon-selected-custom-field-range
              [control]="$any(form.get('range_id'))"
              [selectedCustomField]="wfSelectedCustomField ? wfSelectedCustomField : settedData" />
          </div>
        }
      }
    }

    <div class="w-full">
      <small class="block leading-6 text-xs">
        {{
          wfSelectedCustomField && wfSelectedCustomField.keyword?.translations?.[0]?.description
        }}</small
      >
    </div>
  </div>

  @if (wfSelectedCustomField?.keyword?.translations?.[0]?.description) {
    <small class="block mt-1 leading-6 text-xs">
      {{ wfSelectedCustomField?.keyword?.translations?.[0]?.description }}
    </small>
  }

  <div class="flex flex-row-reverse flex-wrap pt-4">
    <div class="flex items-center justify-center">
      <p-button
        icon="pi pi-save"
        iconPos="left"
        type="submit"
        [styleClass]="'w-full'"
        label="{{ 'Save' | translate }}"
        [disabled]="disabled"
        [rounded]="true" />
    </div>
  </div>
</form>
