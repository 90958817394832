import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputTextModule } from 'primeng/inputtext';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-input-text',
  imports: [InputTextModule, ReactiveFormsModule, InputGroupModule, InputGroupAddonModule],
  template: `
    <p-inputgroup>
      @if ($any(selectedCustomField)?.prefix?.[0]?.translations?.[0]?.name) {
        <p-inputgroup-addon>
          {{ $any(selectedCustomField)?.prefix?.[0]?.translations?.[0]?.name }}
        </p-inputgroup-addon>
      }
      <input type="text" pInputText class="w-full" pInputText [formControl]="control" />
      @if ($any(selectedCustomField)?.suffix?.[0]?.translations?.[0]?.name) {
        <p-inputgroup-addon>
          {{ $any(selectedCustomField)?.suffix?.[0]?.translations?.[0]?.name }}
        </p-inputgroup-addon>
      }
    </p-inputgroup>
  `,
  styles: ``,
})
export class SelectedCustomFieldInputTextComponent extends AbstractSelectedCustomFieldComponent {}
