import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SelectModule } from 'primeng/select';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-dropdown',
  imports: [SelectModule, ReactiveFormsModule],
  template: `
    <ng-container>
      <p-select
        optionLabel="name"
        [required]="true"
        [optionValue]="'id'"
        [styleClass]="'w-full'"
        [options]="this.selectedCustomField?.custom_field_values || []"
        [formControl]="control"
        [showClear]="false">
        <ng-template #item let-option>
          {{ option.translations?.[0]?.name }}
        </ng-template>

        <ng-template #selectedItem let-selectedOption>
          {{ selectedOption.translations?.[0]?.name }}
        </ng-template>
      </p-select>
    </ng-container>
  `,
  styles: ``,
})
export class SelectedCustomFieldDropdownComponent extends AbstractSelectedCustomFieldComponent {}
