import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-input-number',
  imports: [InputNumberModule, ReactiveFormsModule, FormsModule],
  template: `
    @if (control) {
      <p-inputNumber
        #customNumber
        buttonLayout="horizontal"
        inputId="horizontal"
        spinnerMode="horizontal"
        decrementButtonClass="p-button-text p-button-danger py-1 px-1"
        incrementButtonClass="p-button-text p-button-success py-1 px-1"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        [inputStyleClass]="'w-full text-center py-3 px-1 border-transparent text-primary font-bold'"
        [style]="{ width: '100%' }"
        [styleClass]="'border-none '"
        [inputStyleClass]="'w-full'"
        [formControl]="control"
        [autofocus]="true"
        [showButtons]="true"
        [step]="0.01"
        [min]="0.01"
        [max]="9000000000000"
        [suffix]="suffix || ''" />
    }
  `,
  styles: ``,
})
export class SelectedCustomFieldInputNumberComponent extends AbstractSelectedCustomFieldComponent {}
