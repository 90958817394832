import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
  CustomFieldTemplateState,
  customFieldTemplateAdapter,
} from './custom-field-template.reducer';

// Lookup the 'CustomFieldTemplate' feature state managed by NgRx
export const selectCustomFieldTemplateState = createFeatureSelector<CustomFieldTemplateState>(
  CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
);

const { selectAll, selectEntities } = customFieldTemplateAdapter.getSelectors();

export const selectCustomFieldTemplateLoaded = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => state?.loading,
);

export const selectSelectedCustomFieldList = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => {
    return state;
  },
);
export const selectCurrentCustomFieldList = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => state.paginatedWfSelectedCustomFieldList?.results,
);

export const selectCustomFieldTemplateError = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => state.error,
);

export const selectAllCustomFieldTemplate = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => selectAll(state),
);

export const selectCustomFieldTemplateEntities = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => selectEntities(state),
);

export const selectSelectedId = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => state.selectedId,
);

export const selectEntity = createSelector(
  selectCustomFieldTemplateEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);

export const selectPaginatedWfSelectedCustomFieldList = createSelector(
  selectCustomFieldTemplateState,
  (state: CustomFieldTemplateState) => state.paginatedWfSelectedCustomFieldList,
);
