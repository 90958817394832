import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CustomFieldTemplateEffects } from './store/custom-field-template.effects';
import * as fromCustomFieldTemplate from './store/custom-field-template.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(
      fromCustomFieldTemplate.CUSTOM_FIELD_TEMPLATE_FEATURE_KEY,
      fromCustomFieldTemplate.customFieldTemplateReducer,
    ),
    EffectsModule.forFeature([CustomFieldTemplateEffects]),
  ],
})
export class CustomFieldSelectEditorRenderModule {}
