import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { PaginatedCommonCustomFieldCategoryList } from '@offconon/core-api';

import * as CustomFieldTemplateActions from './custom-field-template.actions';
import { CustomFieldTemplateEntity } from './custom-field-template.models';

export const CUSTOM_FIELD_TEMPLATE_FEATURE_KEY = 'customFieldTemplate';

export interface CustomFieldTemplateState extends EntityState<CustomFieldTemplateEntity> {
  selectedCustomFieldList?: any;
  selectedId?: string | number; // which CustomFieldTemplate record has been selected
  loading: boolean; // has the CustomFieldTemplate list is loading
  error?: string | null; // last known error (if any)

  paginatedWfSelectedCustomFieldList?: PaginatedCommonCustomFieldCategoryList;
}

export interface CustomFieldTemplatePartialState {
  readonly [CUSTOM_FIELD_TEMPLATE_FEATURE_KEY]: CustomFieldTemplateState;
}

export const customFieldTemplateAdapter: EntityAdapter<CustomFieldTemplateEntity> =
  createEntityAdapter<CustomFieldTemplateEntity>();

export const initialCustomFieldTemplateState: CustomFieldTemplateState =
  customFieldTemplateAdapter.getInitialState({
    loading: false,
  });

const reducer = createReducer(
  initialCustomFieldTemplateState,
  on(CustomFieldTemplateActions.initCustomFieldTemplate, (state) => ({
    ...state,
    loading: false,
    error: null,
  })),

  on(CustomFieldTemplateActions.loadCustomFieldTemplateFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(CustomFieldTemplateActions.loadSelectedCustomFieldTemplateFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(
    CustomFieldTemplateActions.fetchCustomFieldTemplateSuccess,
    (state, { paginatedWfSelectedCustomFieldList }) => ({
      ...state,
      paginatedWfSelectedCustomFieldList,
    }),
  ),
  on(
    CustomFieldTemplateActions.fetchSelectedCustomFieldTemplateSuccess,
    (state, { paginatedWfSelectedCustomFieldList }) => ({
      ...state,
      selectedCustomFieldList: paginatedWfSelectedCustomFieldList,
    }),
  ),
  on(CustomFieldTemplateActions.selectCustomField, (state, { selectedCustomField }) => {
    return {
      ...state,
      selectedCustomField,
    };
  }),

  on(CustomFieldTemplateActions.setLoaded, (state, { loading }) => ({
    ...state,
    loading,
  })),

  on(
    CustomFieldTemplateActions.createCustomFieldTemplateSuccess,
    (state, { customFieldTemplate }) => ({
      ...state,
      paginatedWfSelectedCustomFieldList: {
        ...state?.paginatedWfSelectedCustomFieldList,
        results: [
          ...(state.paginatedWfSelectedCustomFieldList?.results || []),
          customFieldTemplate,
        ],
        total_objects: (state?.paginatedWfSelectedCustomFieldList?.total_objects || 0) + 1,
      },
    }),
  ),

  on(CustomFieldTemplateActions.deleteCustomFieldTemplateSuccess, (state, { id }) => ({
    ...state,
    paginatedWfSelectedCustomFieldList: {
      ...state?.paginatedWfSelectedCustomFieldList,
      results: state.paginatedWfSelectedCustomFieldList?.results?.filter((item) => item?.id !== id),
      total_objects: (state?.paginatedWfSelectedCustomFieldList?.total_objects || 0) - 1,
    },
  })),
  on(CustomFieldTemplateActions.deleteSelectedCustomFieldTemplateSuccess, (state, { id }) => {
    return {
      ...state,
      selectedCustomFieldList: {
        ...state?.selectedCustomFieldList,
        results: state.selectedCustomFieldList?.results?.filter(
          (item: any) => Number(item?.selected_custom_field.id) !== Number(id),
        ),
        total_objects: Math.max(0, (state?.selectedCustomFieldList?.total_objects || 0) - 1),
      },
    };
  }),
);

export function customFieldTemplateReducer(
  state: CustomFieldTemplateState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
