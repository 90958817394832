import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

import { AbstractSelectedCustomFieldComponent } from '../class/abstract-selected-custom-field.component';

@Component({
  selector: 'offconon-selected-custom-field-checkbox',
  imports: [InputSwitchModule, ReactiveFormsModule, FormsModule],
  template: `
    <div class="grid formgrid p-fluid">
      @for (
        custom_field_value of this.selectedCustomField?.custom_field_values;
        track custom_field_value;
        let index = $index
      ) {
        <div class="field  mt-2">
          <span class="inline-flex justify-content-center align-items-center">
            <p-inputSwitch
              [ngModelOptions]="{ standalone: true }"
              [inputId]="'switch' + index"
              [trueValue]="custom_field_value.id"
              [(ngModel)]="inputSwitchList[custom_field_value.id || 0]"
              (onChange)="onInputSwitchChanged()" />
            <label class="ml-3 mb-1">{{ custom_field_value.translations?.[0]?.name }}</label>
          </span>
        </div>
      }
    </div>
  `,
  styles: [],
})
export class SelectedCustomFieldCheckboxComponent
  extends AbstractSelectedCustomFieldComponent
  implements OnChanges
{
  inputSwitchList: any = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedCustomField'] && this.selectedCustomField) {
      this.initializeInputSwitchList();
    }
  }

  initializeInputSwitchList() {
    this.inputSwitchList = [];
    let selectedFieldValues = [];
    if (this.control?.value && this.control.value.length) {
      selectedFieldValues = this.control.value;
    }

    this.selectedCustomField?.custom_field_values?.forEach((fieldValue: any) => {
      this.inputSwitchList[fieldValue.id] = selectedFieldValues.includes(fieldValue.id)
        ? fieldValue.id
        : null;
    });
  }

  onInputSwitchChanged() {
    const filteredArray = Object.keys(this.inputSwitchList)
      .filter((key) => this.inputSwitchList[key])
      .map((key) => parseInt(key, 10));
    this.control.setValue(filteredArray);
  }
}
