import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommonHelperService } from '@offconon/shared/utils/services';

@Component({
  selector: 'offconon-selected-custom-field-editor-element',
  templateUrl: './selected-custom-field-editor-element.component.html',
  imports: [TranslateModule],
})
export class SelectedCustomFieldEditorElementComponent implements OnInit {
  @Input() selectedCustomField: any;
  @Input() is_editable = true;
  @Output() editClickEvent = new EventEmitter<any>();
  @Output() deleteClickEvent = new EventEmitter<any>();

  selectedValue: string | number | null | undefined = '';

  prefix: string | number | null | undefined;
  suffix: string | number | null | undefined;

  public commonHelperService = inject(CommonHelperService);

  ngOnInit(): void {
    this.prefix = this.selectedCustomField?.custom_field?.prefix?.translations?.[0].name;
    this.suffix = this.selectedCustomField?.custom_field?.suffix?.translations?.[0].name;

    switch (this.selectedCustomField?.custom_field?.custom_type) {
      case 'text':
        let text_type = '';
        let text_symbol = '';
        if (this.selectedCustomField.custom_field?.si_filter_access === 'Yes') {
          text_type = this.selectedCustomField.custom_field.si_unit.unit_type || ' ';
          text_symbol = this.selectedCustomField.custom_field.si_unit.symbols || ' ';
          this.selectedValue =
            text_type + ': ' + this.selectedCustomField.custom_text.toString() + ' ' + text_symbol;
        } else {
          this.selectedValue = this.selectedCustomField.custom_text.toString();
        }
        break;
      case 'radio':
      case 'dropdown':
        this.selectedValue =
          this.selectedCustomField?.selected_custom_field?.selected_value?.translations?.[0]
            ?.name || '';
        break;
      case 'checkbox':
      case 'multi_select':
        this.selectedValue = (
          this.selectedCustomField?.selected_custom_field?.selected_values || []
        )
          .map(({ translations = [] }: { translations: any[] }) => translations?.[0]?.name || '')
          .join(', ');
        /*this.selectedValue =
          this.selectedCustomField?.selected_custom_field?.selected_value?.translations?.[0]
            ?.name || '';*/
        break;
      case 'input_number':
        let type = '';
        let symbol = '';
        if (this.selectedCustomField.custom_field?.si_filter_access === 'Yes') {
          type = this.selectedCustomField.custom_field.si_unit.unit_type || ' ';
          symbol = this.selectedCustomField.custom_field.si_unit.symbols || ' ';
          this.selectedValue =
            type + ': ' + this.selectedCustomField.custom_number.toString() + ' ' + symbol;
        } else {
          this.selectedValue = this.selectedCustomField.custom_number.toString();
        }

        break;
      case 'range':
        this.selectedValue = `${this.selectedCustomField.selected_custom_field.selected_range_value.from_value} - ${this.selectedCustomField.selected_custom_field.selected_range_value.to_value}`;
        break;
    }
  }

  editClick() {
    this.editClickEvent.emit(this.selectedCustomField);
  }

  deleteClick() {
    this.deleteClickEvent.emit(this.selectedCustomField);
  }
}
